import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Link as MuiLink,
} from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';

import { items } from '../../../utils';
import Layout from '../../../components/Layout';
import SEO from '../../../components/SEO';
import ThumbnailItem from '../../../components/ThumbnailItem';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    marginBotom: theme.spacing(4),
  },
}));

const Page = ({ pageContext }) => {
  const { speaker, sermons, pageNum, totalPages } = pageContext;

  const classes = useStyles();

  return (
    <Layout>
      <SEO title={`Media | ${speaker.name}`} />

      <div className={classes.heading}>
        <Typography variant="h1">Media</Typography>
        <Typography variant="subtitle1">
          <MuiLink component={Link} to={`/speakers/${speaker.slug}`}>
            {speaker.name}
          </MuiLink>
        </Typography>
      </div>

      <Box mb={8}>
        <Divider />
      </Box>

      <Grid container spacing={2}>
        {sermons.map((sermon) => (
          <Grid key={sermon.id} item xs={12} md={6}>
            <ThumbnailItem
              title={sermon.title}
              subtext={items(sermon.speakers)
                .map(({ name }) => name)
                .join(', ')}
              timestamp={sermon.date}
              image={`https://i.ytimg.com/vi/${sermon.youtubeId}/hqdefault.jpg`}
              to={`/media/sermons/${sermon.slug}`}
            />
          </Grid>
        ))}
      </Grid>

      <div className={classes.pagination}>
        <Pagination
          size="large"
          page={pageNum}
          count={totalPages}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              component={Link}
              to={
                item.page === 1
                  ? `/speakers/${speaker.slug}`
                  : `/speakers/${speaker.slug}/sermons/${item.page}`
              }
            />
          )}
        />
      </div>
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Page;
